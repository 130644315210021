import classNames from 'classnames';
import { ReactNode } from 'react';

export default function Message({
  type,
  children,
}: {
  type: 'info' | 'warning' | 'error' | 'success';
  children: ReactNode;
}) {
  return (
    <div
      className={classNames('p-3 border rounded-md border-l-[4px] text-gray-900 w-full text-sm', {
        'border-blue-500 bg-blue-50': type === 'info',
        'border-yellow-500 bg-yellow-50': type === 'warning',
        'border-red-500 bg-red-50': type === 'error',
        'border-green-500 bg-green-50': type === 'success',
      })}
    >
      {children}
    </div>
  );
}
